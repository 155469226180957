<template>
  <div class="">
    <!--Filters-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Filters") }}</h5>
          </b-col>
          <b-col>
            <div class="d-flex justify-content-end">
              <b-link style="color: inherit" @click="refresh()">
                <feather-icon icon="RefreshCcwIcon" size="16"/>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>


          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Id") }}</label>
            <b-form-input
                v-model="filter.like.id"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Company") }}</label>
            <b-form-input
                v-model="filter.like.company"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Address") }}</label>
            <b-form-input
                v-model="filter.like.address"
                class="d-inline-block mr-1"
            />
          </b-col>

          <!-- Type-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Zip") }}</label>
            <b-form-input
                v-model="filter.like.zip"
                class="d-inline-block mr-1"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Location") }}</label>
            <b-form-input
                v-model="filter.like.location"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Usage") }}</label>
            <b-form-input
                v-model="filter.like.usage"
                class="d-inline-block mr-1"
            />
          </b-col>
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Total Space") }}</label>
            <b-form-input
                v-model="filter.like.space"
                class="d-inline-block mr-1"
            />
          </b-col>

          <!--
                    <b-col cols="12" md="6" lg="4">

                      <b-form-group :label="msg('Estate agents')" label-for="realEstate-branch">
                        <v-select
                            multiple

                            :dir="isRTL ? 'rtl' : 'ltr'"
                            :clearable="true"
                            v-model="filter.in.userIds"
                            :options="allAgents"
                            class="w-100"
                            label="name"
                            :reduce="(branch) => branch.id"

                        />
                      </b-form-group>
                    </b-col>
          -->

          <b-col cols="12" md="6" lg="4">

            <b-form-group :label="msg('Bank')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  v-model="filter.in.activeBanksIds"
                  :options="allBanks"
                  class="w-100"
                  label="name"
                  :reduce="(bank) => bank"

              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">

            <b-form-group :label="msg('Refinanced')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  v-model="filter.eq.isRefinanced"
                  label="label"
                  :options="[{value: null,label:msg('All')},{value:true,label:msg('Refinanzierung gesendet')}]"
                  class="w-100"
                  :reduce="(type) => type.value"

              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Interest") }} bigger than </label>
            <b-form-input
                v-model="filter.gte.interest"
                class="d-inline-block mr-1"
            />
          </b-col>


          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>{{ msg("Interest") }} less than </label>
            <b-form-input
                v-model="filter.lte.interest"
                class="d-inline-block mr-1"
            />
          </b-col>


          <!--featured status-->
          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <b-form-group :label="msg('Status')" label-for="realEstate-branch">
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  v-model="filter.eq.status"
                  :options="allStatuses"
                  class="w-100"
                  label="text"
                  :reduce="(branch) => branch.value"

              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!--Uploads buildings excel-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{ msg("Upload") }} {{ msg("Buildings") }}</h5>
          </b-col>

        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row class="mb-1">

          <b-col cols="2" md="2" class="d-flex align-items-center justify-content-start">
            <b-button class="text-nowrap" variant="primary" v-b-tooltip
                      :title="msg('Click here to download the buildings excel template')"
                      @click="downloadExcelTemplate">
              <i class="far fa-file-excel mr-1"></i>
              <span>Excel template</span>
            </b-button>
          </b-col>

          <b-col cols="1" md="1" class="d-flex align-items-center justify-content-start mr-3">
            <b-button variant="primary" @click="resetFile">
              <span class="text-nowrap">{{ msg('Reset file') }}</span>
            </b-button>
          </b-col>
          <b-col cols="4" md="4" class="d-flex align-items-center justify-content-start mr-1  ml-3 ">
            <v-select
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="buid"
                placeholder="Branch"
                :options="allBranches"
                class="w-100"
                label="name"
                :reduce="(branch) => branch.id"
                :clearable="false"

            />
          </b-col>

          <b-col v-if="buid!=null" cols="4" lg="4" md="4" class="align-items-center  justify-content-end ">
            <div>
              <b-form-file

                  v-model="file"
                  :placeholder="msg('Drop your building excel file here...')"
                  :drop-placeholder="msg('Drop file here...')"
              />
            </div>

          </b-col>

        </b-row>

      </b-card-body>
    </b-card>
    <!--Uploads client + connect to building excel-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <b-row no-gutters class="w-100">
          <b-col>
            <h5>{{
                msg("uploadbuildingtext")
              }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="buildings!=null && buildings.length>0">
        <b-row class="mb-1 ">

          <b-col cols="2" md="2" class="d-flex align-items-center justify-content-start">
            <b-button class="text-nowrap" variant="primary" v-b-tooltip
                      :title="msg('Click here to download the client excel template')"
                      @click="downloadExcelTemplateClients">
              <i class="far fa-file-excel mr-1"></i>
              <span>Excel template</span>
            </b-button>
          </b-col>

          <b-col cols="1" md="1" class="d-flex align-items-center justify-content-start mr-3">
            <b-button variant="primary" @click="resetFileClients">
              <span class="text-nowrap">{{ msg('Reset file') }}</span>
            </b-button>
          </b-col>
          <b-col cols="4" md="4" class="d-flex align-items-center justify-content-start mr-1  ml-3 ">
            <v-select
                placeholder="Building"
                :dir="isRTL ? 'rtl' : 'ltr'"
                v-model="buildingId"
                :options="buildings"
                class="w-100"
                label="address"
                :reduce="(building) => building.id"
            />
          </b-col>

          <b-col v-if="buildingId!=null" cols="4" lg="4" md="4" class="align-items-center  justify-content-end ">
            <div>
              <b-form-file

                  v-model="fileClient"
                  :placeholder="msg('Drop your client excel file here...')"
                  :drop-placeholder="msg('Drop file here...')"
              />
            </div>

          </b-col>

        </b-row>

      </b-card-body>
    </b-card>
    <b-card no-body>
      <div class="card-header" style="overflow: hidden">
        <span class="card-title m-0">{{ msg('Buildings') }}</span>
        <b-col>
          <div class="d-flex justify-content-end">
            <b-link style="color: inherit" @click="refresh()">
              <feather-icon icon="RefreshCcwIcon" size="16"/>
            </b-link>
          </div>
        </b-col>
      </div>
      <!--Card Content-->
      <b-card-body>
        <div class="">
          <!-- Table Top -->
          <b-row no-gutters class="mb-1">
            <!-- Per Page -->
            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>{{ msg('Show') }}</label>
              <v-select
                  v-model="page.amount"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="[10, 25, 50, 100]"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
              />
              <label>{{ msg('entries') }}</label>
            </b-col>
            <!-- Search -->
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                    v-model="filter.like.search"
                    class="d-inline-block"
                    :placeholder="msg('Search')"
                />
                <span class="mr-1"></span>
                <b-button variant="primary" @click="onBuildingSelect(0)">
                  <span class="text-nowrap">{{ msg('New building') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>
        <!--Table-->
        <b-table
            ref="building-table"
            striped
            hover
            responsive
            v-bind:items="buildings"
            v-bind:fields="fields"
            :busy="loading"
            @sort-changed="onSort"
            @row-clicked="onRowClick"
        >

          <template #cell(interest)="data" class="p-0">

            <b-badge v-if="data.value!=null&& data.value!==''" variant="success">{{ data.value }}%</b-badge>
          </template>

          <template #cell(activeBanks)="data" class="p-0">

            <b-badge v-for="(bank,index) in data.value" v-bind:key="bank.name+index"
                     :class="index===data.value.length-1?'':'mb-1'" variant="info">{{ bank.name }}
            </b-badge>
          </template>

          <template #cell(refinanceId)="data" class="p-0">

            <b-badge :variant="data.value!=null&& data.value!==''? 'success':'danger'">
              {{ data.value != null && data.value !== '' ? msg('Yes') : msg('No') }}
            </b-badge>
          </template>

          <template #cell(status)="data">
            <b-badge variant="primary">{{ msg(data.value) }}</b-badge>
          </template>

          <template #cell(buildingInvoices)="data">
            <b-badge :variant="getBadgeVariant(getCountOfOpenInvoices(data.value))">
              {{ getCountOfOpenInvoices(data.value) }}
            </b-badge>
          </template>
          <template #cell(userIds)="data">
            <div v-for="userId in data.value" v-bind:key="userId">
              <b-badge class="mb-1" variant="info">{{ getUserFullName(userId) }}</b-badge>
            </div>
          </template>
          <template #cell(offerTemplateIds)="data">
            <i v-if="data.item.offerTemplateIds==null" class="fas fa-warning" style="color: red"></i>
            <i v-else class="fas fa-check" style="color: green"></i>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mx-1"></b-spinner>
              <strong>{{ msg('Loading') }}...</strong>
            </div>
          </template>
          <template #cell(creationDate)="data">
            {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>
          <template #cell(propertyIds)="data">
            {{ data.value != null ? data.value.length : 0 }}
          </template>
          <template #cell(updatedDate)="data">
            {{ $moment(data.value, 'YYYY-MM-DD HH:mm').format("DD/MM/YY HH:mm") }}
          </template>

          <template #cell(attachments)="data">
            <b-img
                v-bind=" { blank: (data.value == null || data.value[0] == null), blankColor: '#777', width: 50, height: 50,
                                src:(data.value != null && data.value[0] != null ? mergeSrcUrl(getRealEstatesWebSite,data.value[0]) : null)  }"
                blank-color="#475F7B"
                rounded
                alt="Rounded image"
                class="d-inline-block m-1"
            />
          </template>
        </b-table>

        <!--Table Paginator-->
        <div class="text-center w-100">
          <div class="d-inline-block">
            <b-pagination
                v-model="page.currentPage"
                :total-rows="page.total"
                :per-page="page.amount"
                first-number
                last-number
                @input="refresh"
            />
          </div>
          <div class="d-inline-block px-2"><strong>{{ msg('Total') }}</strong> {{ page.total }}</div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal no-fade v-model="dialogs.building" size="xl" ref="building-modal" id="building-modal"
             :hide-footer="true" no-close-on-backdrop centered>
      <building-panel ref="realEstatePanel" @submitted="onSubmitted"/>
      <div class="text-center" style="justify-content:center">
        <b-button variant="outline-primary" class="btn-dialog" @click="submitBuilding">
          {{ msg('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BuildingPanel from './buildingPanel'

export default {
  name: 'Buildings',
  components: {
    BuildingPanel
  },
  data() {
    return {
      excelUrlBuildings: "",
      excelUrlClients: "",
      file: null,
      fileClient: null,
      loading: true,
      amount: 10,
      bankList: [],
      buildings: [],
      fields: [],
      buildingId: null,
      buid: null,
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}},
        lte: {},
        gte: {},
        in: {activeBanksIds: []}
      },
      sort: {
        by: 'updatedDate',
        desc: true,
        direction: 'desc'
      },
      statusVariants:
          {"NEW": "primary", "ACTIVE": "success", "EXPOSE": "warning", "DISABLED": "danger"},

      page: {
        amount: 10,
        currentPage: 1,
        total: 0
      },
      dialogs: {building: false},
      addressName: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      featureInfoModal: {
        id: 'feature-modal',
        title: '',
        content: ''
      },
      //Listeners
      listeners: {
        refresh: () => {
        }
      }

    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
    file: {
      handler() {
        this.uploadFile()
      }
    }, fileClient: {
      handler() {
        this.uploadFileClient()
      }
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationWebSite', 'getRealEstatesWebSite']),
    ...mapGetters('data', ['getUserFullName', 'getUserAcronyms']),
    ...mapGetters('data', ['getAllUsers', 'getUsersInTeam', 'getUserRole', 'getRoles', 'getRoleName']),
    ...mapGetters('translator', ['msg']),
    ...mapGetters('building', ['allStatuse']),
    ...mapGetters('data', ['getAllBranches']),
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allStatuses() {
      return Object.values(this.allStatuse)
    },
    allBanks() {
      return this.bankList
          .map(bank => ({
            id: bank.id,
            name: (bank.name)
          }))
    },

    allbuildings() {
      return this.buildings
    }
  },
  created() {
    let $this = this;
    Promise.all([this._loadBankList({amount: 9999}).then(ans => {
      $this.bankList = ans["Banks"];

    })]).finally(() => {
          if (typeof $this.building !== 'undefined') {
            $this.currentBankList = $this.building.activeBanks;
          }
        }
    )
    this.setFields();
    this.refresh()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      $this.refresh()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
  },
  methods: {
    ...mapActions('building', ['getAllBuildings', 'copySuccess', 'getExcelTemplateUrl', 'getExcelTemplateUrlClients']),
    ...mapActions('building', {_uploadExcel: 'uploadExcel', _uploadExcelClient: 'uploadExcelClient'}),
    ...mapActions('bank', {
      _loadBankList: 'getAllBanks'
    }),
    uploadFile() {
      let $this = this
      if (this.file == null)
        return;

      let paramMap = {"buid": this.buid}
      this._uploadExcel({file: this.file, params: paramMap}).finally(() => $this.refresh());

    },
    uploadFileClient() {
      let $this = this
      console.log('uploadExcelClient')
      if (this.fileClient == null)
        return;

      let paramMap = {"buildingId": this.buildingId}
      this._uploadExcelClient({fileClient: this.fileClient, params: paramMap}).finally(() => $this.refresh());

    },
    resetFile() {
      this.file = null;
    },
    resetFileClients() {
      this.fileClient = null;
    },
    downloadExcelTemplate() {
      console.log("building excel template url :", this.excelUrlBuildings)
      window.open(this.excelUrlBuildings, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    },
    downloadExcelTemplateClients() {
      console.log("client excel template url :", this.excelUrlClients)
      window.open(this.excelUrlClients, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    },

    onCopy: function (e) {
      this.copySuccess(e.text)
    },
    onError: function (e) {
      alert('Failed to copy the text to the clipboard')
    },

    setFields() {
      let $this = this
      this.fields = [
        {
          key: 'updatedDate',
          label: $this.msg('Updated at'),
          sortable: true
        },
        {
          key: 'attachments',
          label: $this.msg('Main image'),
          sortable: true,
          active: true,
        },
        {
          key: 'company',
          label: $this.msg('Company'),
          sortable: true
        },
        {
          key: 'address',
          label: $this.msg('Address'),
          sortable: true
        },
        {
          key: 'buildingInvoices',
          label: $this.msg('Open Invoices'),
          sortable: true,
          active: true,
        },
        {
          key: 'propertyIds',
          label: $this.msg('units'),
          sortable: true,
          active: true,
        },
        {
          key: 'status',
          label: $this.msg('Status'),
          sortable: true
        },


        {
          key: 'offerTemplateIds',
          label: $this.msg('Template'),
          sortable: true,
          active: true,
        },

        {
          key: 'userIds',
          label: $this.msg('Users'),
          sortable: true,
          active: true,
        },


      ]
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    infoFeature(item, index, button) {
      this.featureInfoModal.title = `Row index: ${index}`
      this.featureInfoModal.propertyId = item.id;
      this.addressName = item.address != null ? item.address : "Die Addresse ist leer";
      this.$root.$emit('bv::show::modal', this.featureInfoModal.id, button)
    },
    copyId(id) {
      console.log("the id copied was: ", id)
    },
    resetInfoModal() {
      this.$root.$emit('bv::hide::modal', this.infoModal.id)
    },
    resetFeatureInfoModal() {
      this.$root.$emit('bv::hide::modal', this.featureInfoModal.id)
    },
    refresh() {
      let $this = this
      this.loading = true;
      this.getAllBuildings({
        page: $this.page.currentPage,
        amount: $this.page.amount,
        filter: $this.filter,
        sortcolumn: 'updatedDate',
        sortby: 'desc',
      }).then(this.onBuildingsRetrieve, (error) => $this.error = error)

      this.getExcelTemplateUrl().then((url) => $this.excelUrlBuildings = url);
      this.getExcelTemplateUrlClients().then((url) => $this.excelUrlClients = url);

    },
    onBuildingsRetrieve(data) {
      this.page = {
        amount: data['Amount'],
        total: data['Total'],
        currentPage: data['Page']
      };
      this.sort = {
        by: data["sortColumn"],
        direction: data["sortOrder"]
      }
      this.buildings = data["Buildings"];
      this.loading = false;
    },
    onBuildingSelect(id) {
      this.selected = id
      if (id === 0) {
        this.dialogs.building = true
      }
    },
    onSort(data) {
      this.sort.by = data.sortBy;
      this.sort.direction = data.sortDesc ? "desc" : "asc";
      this.refresh();
    },
    onRowClick(building) {
      this.$router.push(`/building/edit/${building.id}`);
    },
    submitBuilding() {
      this.$root.$emit('crm::submitBuilding')
    },
    onSubmitted(server) {
      if (server.id != null)
        this.selected = server.id;
      this.refresh()
      this.dialogs.building = false;
    },
    getBadgeVariant(count) {
      if (count === 1) {
        return 'warning'; // Green
      } else if (count >= 2) {
        return 'danger'; // Orange
      }
      // Default to primary if count is 0 or undefined
      return 'success';
    },
    getCountOfOpenInvoices(invoices) {
      if (invoices == null) {
        return 0
      }
      let count = 0;
      for (const invoice of invoices) {
        if (invoice.invoiceStatus === 'OPEN') {
          count++;
        }
      }
      return count;
    },
  }
}
</script>

<style>

</style>
